import * as React from 'react';

import { motion } from 'framer-motion';

// const transition = {
//   duration: 1,
//   repeat: Infinity,
//   repeatDelay: 1,
//   repeatType: 'reverse',
//   ease: 'easeInOut'
// };

const colors = [
  '#2A3749',
  '#364860',
  '#4F6B89',
  '#6582A8',
  '#C8CCD4',
  '#B2BC20'
];

const RadialGradient = () => (
  <motion.radialGradient
    id="gradient1"
    fr={0.2}
    fx={0.32}
    fy={0.32}
    r={0.7}
    animate={{ fr: 0.05, fx: 0.2, fy: 0.35, r: 0.6 }}
    transition={{
      repeat: Infinity,
      repeatType: 'mirror',
      ease: 'easeInOut',
      duration: 3
    }}
  >
    <stop offset="100%" stopColor={colors[4]} />

    <stop offset="50%" stopColor={colors[0]} />
  </motion.radialGradient>
);

const linearTransition = {
  repeat: Infinity,
  repeatType: 'reverse',
  ease: 'easeInOut',
  duration: 10
};
const LinearGradient = () => (
  <motion.linearGradient
    id="gradient2"
    transition={linearTransition}
    gradientTransform="rotate(45)"
    animate={{ gradientTransform: ['rotate(45)', 'rotate(90)', 'rotate(45)'] }}
  >
    <motion.stop
      offset="5%"
      stopColor={colors[5]}
      animate={{
        offset: ['5%', '10%', '15%', '30%', '25%', '5%']
      }}
      transition={linearTransition}
    />
    <stop offset="95%" stopColor={colors[2]} />
  </motion.linearGradient>
);

const paths = [
  /** d-circle */
  'M54 46L52 43.25L51.8424 43.0334C50.6293 41.3653 49.0607 39.9877 47.25 39V39V39C45.4378 38.0115 43.4345 37.4239 41.3755 37.2768L41 37.25V37.25C38.7041 37.086 36.404 37.5059 34.314 38.4704L33.9119 38.656C31.9914 39.5424 30.2746 40.8161 28.8693 42.397L28.7024 42.5849C27.576 43.852 26.678 45.305 26.0484 46.8791V46.8791C25.3558 48.6105 25 50.4581 25 52.3228V52.8932C25 54.6152 25.3286 56.3215 25.9681 57.9203V57.9203C26.6489 59.6224 27.6684 61.1684 28.9646 62.4646L29.0804 62.5804C30.0222 63.5222 31.0907 64.3281 32.2549 64.975L32.9948 65.386C34.3236 66.1242 35.7588 66.6518 37.2493 66.9499V66.9499C39.0667 67.3133 40.9364 67.3297 42.7599 66.9982L42.9493 66.9638C44.6383 66.6567 46.2656 66.0747 47.7663 65.2409L48.3009 64.9439C49.4281 64.3177 50.4626 63.5374 51.3744 62.6256V62.6256C52.7752 61.2248 53.8599 59.5403 54.5555 57.6853L55 56.5',
  /** d-line */
  'M54 12C54.9091 16.9107 55.6364 19.8571 55.6364 38.0268C55.6364 49.8125 54.2727 58.6518 57 67',
  /** i-line */
  'M63.9408 39C64.6742 41.4698 65.2956 42.9648 64.6545 51.8614C64.2386 57.6321 62.5666 61.8621 64.9922 66.1458',
  /** g-circle */
  'M102.019 48.1675L100.659 45.0508L100.552 44.8053C99.7273 42.9148 98.4928 41.2312 96.9378 39.8761V39.8761V39.8761C95.3816 38.52 93.5521 37.514 91.5734 36.9262L91.2125 36.819V36.819C89.006 36.1635 86.6695 36.0773 84.4207 36.5683L83.988 36.6627C81.9215 37.1139 79.9704 37.9872 78.2571 39.2277L78.0536 39.3751C76.6803 40.3694 75.49 41.5945 74.5356 42.9957V42.9957C73.4859 44.5369 72.7398 46.2642 72.3375 48.085L72.2145 48.642C71.843 50.3235 71.7957 52.0604 72.0753 53.7596V53.7596C72.3729 55.5684 73.0347 57.298 74.0208 58.8433L74.1089 58.9814C74.8253 60.1042 75.6948 61.1216 76.6921 62.0044L77.3258 62.5654C78.464 63.5728 79.7517 64.3976 81.1428 65.0103V65.0103C82.839 65.7573 84.6611 66.1767 86.5132 66.2463L86.7055 66.2535C88.421 66.3181 90.1355 66.1008 91.7808 65.6105L92.3669 65.4358C93.6027 65.0675 94.7811 64.5288 95.8681 63.8352V63.8352C97.5382 62.7695 98.9607 61.3587 100.04 59.6975L100.73 58.636',
  /** g-line */
  'M102.109 36.0157C102.582 40.8154 104 43.5 100.5 61C99.2207 67.3964 99.5662 78.099 100 86C100.5 89 96.5 87 96.5 87C96.5 87 93.5 85.5 92 84',
  /** i-line */
  'M112.974 38.9271C113.708 41.3969 114.329 42.8919 113.688 51.7885C113.272 57.5592 111.6 61.7892 114.026 66.0729',

  /** t-line */
  'M123 13C123.909 17.8214 124.636 20.7143 124.636 38.5536C124.636 50.125 123.273 58.8036 126 67',
  /** t-cross */
  'M139.354 32.4607C136.779 32.6544 135.222 32.8711 125.922 31.7279C119.89 30.9863 115.458 29.6761 111 30.6589',

  /** a-circle */
  'M162.955 47.5445L161.596 44.4278L161.489 44.1822C160.664 42.2917 159.43 40.6081 157.875 39.2531V39.2531V39.2531C156.318 37.8969 154.489 36.8909 152.51 36.3031L152.149 36.1959V36.1959C149.943 35.5405 147.606 35.4542 145.357 35.9452L144.925 36.0397C142.858 36.4909 140.907 37.3641 139.194 38.6047L138.99 38.7521C137.617 39.7464 136.427 40.9714 135.472 42.3726V42.3726C134.423 43.9138 133.677 45.6411 133.274 47.4619L133.151 48.0189C132.78 49.7004 132.732 51.4374 133.012 53.1366V53.1366C133.31 54.9454 133.972 56.6749 134.958 58.2203L135.046 58.3584C135.762 59.4811 136.632 60.4986 137.629 61.3813L138.263 61.9423C139.401 62.9498 140.688 63.7746 142.08 64.3872V64.3872C143.776 65.1342 145.598 65.5536 147.45 65.6233L147.642 65.6305C149.358 65.695 151.072 65.4778 152.718 64.9875L153.304 64.8128C154.539 64.4445 155.718 63.9058 156.805 63.2121V63.2121C158.475 62.1465 159.898 60.7357 160.977 59.0745L161.667 58.0129',
  /** a-line */
  'M162.217 35C163.064 38.0024 163.783 39.8191 163.003 50.646C162.497 57.6689 160.531 62.8217 163.345 68.0249',
  /** l-line */
  'M172 14C172.909 18.7321 173.636 21.5714 173.636 39.0804C173.636 50.4375 172.273 58.9554 175 67'
];

export function Logo(props) {
  const motionConfig = (i) => ({
    initial: { strokeWidth: 0 },
    animate: {
      pathLength: [0, 1],
      strokeWidth: 4
      // y: [0, -10]
      // opacity: 1
    },
    transition: {
      duration: 0.25,
      delay: 0.1 * i,
      repeat: Infinity,
      repeatDelay: 5,
      repeatType: 'mirror',
      ease: 'easeInOut'
    },
    strokeWidth: '4',

    fill: 'transparent'
  });
  return (
    <svg
      preserveAspectRatio=""
      viewBox="0 0 200 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      style={{
        maxWidth: '80vw',
        minWidth: '50px',
        width: '100%',
        ...props.style
      }}
    >
      <defs>
        <RadialGradient />
        <LinearGradient />
        <mask id="mask1">
          <g stroke="white">
            {paths.map((path, i) => (
              <motion.path
                custom={i}
                key={i}
                d={path}
                {...motionConfig(i)}
                strokeLinecap="round"
              />
            ))}
          </g>
        </mask>
      </defs>

      <motion.rect
        style={{ maxWidth: '80vw' }}
        width="200"
        height="100"
        mask="url(#mask1)"
        fill="url('#gradient2')"
        animate={{
          y: [0, -2, 1, -2.1, 1.4, 1, 0],
          x: [0, -0.2, -0.1, 0.1, 0],
          backdropFilter: 'blur(5px)'
        }}
        transition={linearTransition}
      />
      {/* <g stroke="white">
        {paths.map((path, i) => (
          <motion.path
            custom={i}
            key={i}
            d={path}
            stroke="url('#gradient2')"
            {...motionConfig(i)}
          />
        ))}
      </g> */}
    </svg>
  );
}

export default Logo;
